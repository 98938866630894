// Generated by Framer (b742ddc)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["S_qcbCG75", "Dh4qIx6tD"];

const serializationHash = "framer-e42XR"

const variantClassNames = {Dh4qIx6tD: "framer-v-j7kn51", S_qcbCG75: "framer-v-qk5q0d"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Active: "S_qcbCG75", Inactive: "Dh4qIx6tD"}

const getProps = ({click, height, id, image, width, ...props}) => { return {...props, DAQFG8lyB: click ?? props.DAQFG8lyB, ehyGwppl6: image ?? props.ehyGwppl6 ?? {src: "https://framerusercontent.com/images/soEhh3Q492LPteBJO7cmkJsU.png"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "S_qcbCG75"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ehyGwppl6, DAQFG8lyB, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "S_qcbCG75", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1k3a1wz = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (DAQFG8lyB) {const res = await DAQFG8lyB(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 64, pixelWidth: 64, sizes: componentViewport?.width || "100vw", ...toResponsiveImage(ehyGwppl6), ...{ positionX: "center", positionY: "center" }}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-qk5q0d", className, classNames)} data-border data-framer-name={"Active"} data-highlight layoutDependency={layoutDependency} layoutId={"S_qcbCG75"} onTap={onTap1k3a1wz} ref={ref ?? ref1} style={{"--border-bottom-width": "2px", "--border-color": "rgb(146, 60, 246)", "--border-left-width": "2px", "--border-right-width": "2px", "--border-style": "solid", "--border-top-width": "2px", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, opacity: 1, ...style}} variants={{Dh4qIx6tD: {"--border-color": "rgba(146, 60, 246, 0)", opacity: 0.5}}} {...addPropertyOverrides({Dh4qIx6tD: {"data-framer-name": "Inactive"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-e42XR.framer-1r3mdmy, .framer-e42XR .framer-1r3mdmy { display: block; }", ".framer-e42XR.framer-qk5q0d { cursor: pointer; height: 64px; overflow: hidden; position: relative; width: 64px; will-change: var(--framer-will-change-override, transform); }", ".framer-e42XR.framer-v-j7kn51.framer-qk5q0d { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 64px); }", ".framer-e42XR[data-border=\"true\"]::after, .framer-e42XR [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 64
 * @framerIntrinsicWidth 64
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Dh4qIx6tD":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"ehyGwppl6":"image","DAQFG8lyB":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerBQkVZ_hOq: React.ComponentType<Props> = withCSS(Component, css, "framer-e42XR") as typeof Component;
export default FramerBQkVZ_hOq;

FramerBQkVZ_hOq.displayName = "Helper/ Profile Image";

FramerBQkVZ_hOq.defaultProps = {height: 64, width: 64};

addPropertyControls(FramerBQkVZ_hOq, {variant: {options: ["S_qcbCG75", "Dh4qIx6tD"], optionTitles: ["Active", "Inactive"], title: "Variant", type: ControlType.Enum}, ehyGwppl6: {__defaultAssetReference: "data:framer/asset-reference,soEhh3Q492LPteBJO7cmkJsU.png?originalFilename=Frame+1707479257.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}, DAQFG8lyB: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerBQkVZ_hOq, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})